<script>
  import Vue from 'vue';
  import { Bar } from 'vue-chartjs'
  export default Vue.extend({
    extends: Bar,
    methods: {
    },
    data() {
      return {
        chart : null,
        chartType: 'bar',
        chartData: {
              labels : ["Prev.Period", "Curr.Period"],
              datasets: [
                {
                  label: "IPhone",
                  data: [0,0],
                  backgroundColor: "#01cb63",
                  borderColor: "#01cb63",
                  borderWidth: 3,
                  fill: false,
                  tension: 0,
                  pointRadius: 0,
                  pointHitRadius: 15,
                  barPercentage: 0.5
                },
                {
                  label: "IPad",
                  data: [0,0],
                  backgroundColor: "#c8ccca",
                  borderColor: "#c8ccca",
                  borderWidth: 3,
                  fill: false,
                  tension: 0,
                  pointRadius: 0,
                  pointHitRadius: 15,
                  barPercentage: 0.5
                }
              ],
        },
        chartOptions: {
              legend: {
                  display: true,
                  position: 'bottom',
                  labels: {
                      fontColor: 'rgb(0, 0, 0)'
                  }
              },
              responsive: true,
                  lineTension: 0,
                  scales: {
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: '',
                      },
                      gridLines: {
                          drawOnChartArea: false
                      },
                    }],
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          padding: 25
                        },
                        gridLines: {
                          drawOnChartArea: false
                        }
                      }
                    ]
                  }
            },
      }
    },
    props:{
      deviceData:Object,
    },
    mounted(){
      this.renderChart(this.chartData, this.chartOptions)
    },
    watch: {
      deviceData: function(data) {
        //console.log(data);
        const mp = data.mp;
        const mc = data.mc
        const fp = data.fp;
        const fc = data.fc;
        this.chartData.datasets[0].data = [mp,mc]
        this.chartData.datasets[1].data = [fp,fc,]
        this.renderChart(this.chartData, this.chartOptions)
      }
    }
  });

</script>
