var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("v-app", [
        _c(
          "div",
          { staticClass: "inner-container" },
          [
            _c(
              "div",
              { staticClass: "top-page-header" },
              [
                _c("div", { staticClass: "head-title" }, [
                  _c("h1", [
                    _vm._v(" " + _vm._s(_vm.$t("pages.overview")) + " ")
                  ])
                ]),
                _c(
                  "v-row",
                  { staticClass: "select-area" },
                  [
                    _c(
                      "v-col",
                      { attrs: { lg: "3", md: "4", sm: "6", cols: "12" } },
                      [
                        _c(
                          "v-sheet",
                          { attrs: { color: "white", elevation: "0" } },
                          [
                            _c("v-select", {
                              staticClass: "rounded-lg",
                              attrs: {
                                items: _vm.searchedApps,
                                loading: _vm.loadingApps,
                                label: "Search App",
                                "item-text": "appName",
                                "item-value": "adamId",
                                required: "",
                                outlined: "",
                                dense: "",
                                "prepend-inner-icon": "mdi-cellphone",
                                "return-object": ""
                              },
                              on: { change: _vm.changeApp },
                              model: {
                                value: _vm.searchApp,
                                callback: function($$v) {
                                  _vm.searchApp = $$v
                                },
                                expression: "searchApp"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { lg: "3", md: "4", sm: "6", cols: "12" } },
                      [
                        _c(
                          "v-sheet",
                          { attrs: { color: "white", elevation: "0" } },
                          [
                            _c("v-select", {
                              staticClass: "rounded-lg",
                              attrs: {
                                items: _vm.periods,
                                label: "Select period",
                                required: "",
                                outlined: "",
                                dense: "",
                                "prepend-inner-icon": "mdi-calendar",
                                "item-text": "text",
                                "item-value": "value",
                                "return-object": ""
                              },
                              on: { change: _vm.changePeriod },
                              model: {
                                value: _vm.period,
                                callback: function($$v) {
                                  _vm.period = $$v
                                },
                                expression: "period"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-container",
              { staticClass: "v-main-content", attrs: { fluid: "" } },
              [
                [
                  _c(
                    "div",
                    { staticClass: "colFullOverview" },
                    [
                      _vm.existCL
                        ? _c(
                            "v-btn",
                            {
                              staticClass:
                                "floatingBtn rounded-r-xl-0 rounded-l-xl",
                              attrs: {
                                color: "primary",
                                elevation: "0",
                                dark: "",
                                absolute: "",
                                top: "",
                                right: ""
                              },
                              on: {
                                click: function($event) {
                                  _vm.showCL = ~_vm.showCL
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-check-circle")])],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "col-left-main" },
                        [
                          _c(
                            "v-row",
                            { staticClass: "v-fixed-top-section" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", lg: "2", sm: "4" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "pa-4 rounded-lg",
                                      attrs: { elevation: "3" }
                                    },
                                    [
                                      _c("v-select", {
                                        staticClass: "rounded-lg",
                                        attrs: {
                                          items: _vm.paramsDD11,
                                          required: "",
                                          outlined: "",
                                          dense: "",
                                          "item-text": "text",
                                          "item-value": "value",
                                          "return-object": ""
                                        },
                                        on: { change: _vm.changedd11 },
                                        model: {
                                          value: _vm.dd11.selected,
                                          callback: function($$v) {
                                            _vm.$set(_vm.dd11, "selected", $$v)
                                          },
                                          expression: "dd11.selected"
                                        }
                                      }),
                                      _c(
                                        "v-card-text",
                                        { staticClass: "pa-0 text-center" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-left grey--text"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.period.text) + " "
                                              ),
                                              _vm.dd11.displayCurr
                                                ? _c(
                                                    "strong",
                                                    {
                                                      staticStyle: {
                                                        float: "right",
                                                        color: "black"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " ( In " +
                                                          _vm._s(
                                                            _vm.dd11.curr
                                                          ) +
                                                          " )"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          ),
                                          _c(
                                            "h1",
                                            {
                                              staticClass:
                                                "counts mb-4 font-weight-medium"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.dd11.value) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "periods" },
                                            [
                                              !_vm.dd11.positive
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "primary--text",
                                                      staticStyle: {
                                                        "font-size": "16px"
                                                      }
                                                    },
                                                    [_vm._v("mdi-minus")]
                                                  )
                                                : _vm._e(),
                                              _vm.dd11.positive
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "primary--text",
                                                      staticStyle: {
                                                        "font-size": "16px"
                                                      }
                                                    },
                                                    [_vm._v("mdi-plus")]
                                                  )
                                                : _vm._e(),
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.dd11.percent) +
                                                    " % "
                                                )
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  " / " +
                                                    _vm._s(
                                                      _vm.$t("overview.period")
                                                    )
                                                )
                                              ]),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    top: "",
                                                    "max-width": "300px"
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "ml-2 secondary--text",
                                                                  attrs: {
                                                                    small: ""
                                                                  }
                                                                },
                                                                "v-icon",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "mdi-help-circle"
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "overview.tooltip"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", lg: "2", sm: "4" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "pa-4 rounded-lg",
                                      attrs: { elevation: "3" }
                                    },
                                    [
                                      _c("v-select", {
                                        staticClass: "rounded-lg",
                                        attrs: {
                                          items: _vm.paramsDD12,
                                          required: "",
                                          outlined: "",
                                          dense: "",
                                          "item-text": "text",
                                          "item-value": "value",
                                          "return-object": ""
                                        },
                                        on: { change: _vm.changedd12 },
                                        model: {
                                          value: _vm.dd12.selected,
                                          callback: function($$v) {
                                            _vm.$set(_vm.dd12, "selected", $$v)
                                          },
                                          expression: "dd12.selected"
                                        }
                                      }),
                                      _c(
                                        "v-card-text",
                                        { staticClass: "pa-0 text-center" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-left grey--text"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.period.text) + " "
                                              ),
                                              _vm.dd12.displayCurr
                                                ? _c(
                                                    "strong",
                                                    {
                                                      staticStyle: {
                                                        float: "right",
                                                        color: "black"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " ( In " +
                                                          _vm._s(
                                                            _vm.dd12.curr
                                                          ) +
                                                          " )"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          ),
                                          _c(
                                            "h1",
                                            {
                                              staticClass:
                                                "counts mb-4 font-weight-medium"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.dd12.value) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "periods" },
                                            [
                                              !_vm.dd12.positive
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "primary--text",
                                                      staticStyle: {
                                                        "font-size": "16px"
                                                      }
                                                    },
                                                    [_vm._v("mdi-minus")]
                                                  )
                                                : _vm._e(),
                                              _vm.dd12.positive
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "primary--text",
                                                      staticStyle: {
                                                        "font-size": "16px"
                                                      }
                                                    },
                                                    [_vm._v("mdi-plus")]
                                                  )
                                                : _vm._e(),
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.dd12.percent) +
                                                    " %"
                                                )
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  " / " +
                                                    _vm._s(
                                                      _vm.$t("overview.period")
                                                    )
                                                )
                                              ]),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    top: "",
                                                    "max-width": "300px"
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "ml-2 secondary--text",
                                                                  attrs: {
                                                                    small: ""
                                                                  }
                                                                },
                                                                "v-icon",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "mdi-help-circle"
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "overview.tooltip"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", lg: "2", sm: "4" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "pa-4 rounded-lg",
                                      attrs: { elevation: "3" }
                                    },
                                    [
                                      _c("v-select", {
                                        staticClass: "rounded-lg",
                                        attrs: {
                                          items: _vm.paramsDD13,
                                          required: "",
                                          outlined: "",
                                          dense: "",
                                          "item-text": "text",
                                          "item-value": "value",
                                          "return-object": ""
                                        },
                                        on: { change: _vm.changedd13 },
                                        model: {
                                          value: _vm.dd13.selected,
                                          callback: function($$v) {
                                            _vm.$set(_vm.dd13, "selected", $$v)
                                          },
                                          expression: "dd13.selected"
                                        }
                                      }),
                                      _c(
                                        "v-card-text",
                                        { staticClass: "pa-0 text-center" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-left grey--text"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.period.text) + " "
                                              ),
                                              _vm.dd13.displayCurr
                                                ? _c(
                                                    "strong",
                                                    {
                                                      staticStyle: {
                                                        float: "right",
                                                        color: "black"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " ( In " +
                                                          _vm._s(
                                                            _vm.dd13.curr
                                                          ) +
                                                          " )"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          ),
                                          _c(
                                            "h1",
                                            {
                                              staticClass:
                                                "counts mb-4 font-weight-medium"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.dd13.value) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "periods" },
                                            [
                                              !_vm.dd13.positive
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "primary--text",
                                                      staticStyle: {
                                                        "font-size": "16px"
                                                      }
                                                    },
                                                    [_vm._v("mdi-minus")]
                                                  )
                                                : _vm._e(),
                                              _vm.dd13.positive
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "primary--text",
                                                      staticStyle: {
                                                        "font-size": "16px"
                                                      }
                                                    },
                                                    [_vm._v("mdi-plus")]
                                                  )
                                                : _vm._e(),
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.dd13.percent) +
                                                    " % "
                                                )
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  " / " +
                                                    _vm._s(
                                                      _vm.$t("overview.period")
                                                    )
                                                )
                                              ]),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    top: "",
                                                    "max-width": "300px"
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "ml-2 secondary--text",
                                                                  attrs: {
                                                                    small: ""
                                                                  }
                                                                },
                                                                "v-icon",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "mdi-help-circle"
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "overview.tooltip"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", lg: "2", sm: "4" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "pa-4 rounded-lg",
                                      attrs: { elevation: "3", outlined: "" }
                                    },
                                    [
                                      _c("v-select", {
                                        staticClass: "rounded-lg",
                                        attrs: {
                                          items: _vm.paramsDD14,
                                          required: "",
                                          outlined: "",
                                          dense: "",
                                          "item-text": "text",
                                          "item-value": "value",
                                          "return-object": ""
                                        },
                                        on: { change: _vm.changedd14 },
                                        model: {
                                          value: _vm.dd14.selected,
                                          callback: function($$v) {
                                            _vm.$set(_vm.dd14, "selected", $$v)
                                          },
                                          expression: "dd14.selected"
                                        }
                                      }),
                                      _c(
                                        "v-card-text",
                                        { staticClass: "pa-0 text-center" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-left grey--text"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.period.text) + " "
                                              ),
                                              _vm.dd14.displayCurr
                                                ? _c(
                                                    "strong",
                                                    {
                                                      staticStyle: {
                                                        float: "right",
                                                        color: "black"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " ( In " +
                                                          _vm._s(
                                                            _vm.dd14.curr
                                                          ) +
                                                          " )"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          ),
                                          _c(
                                            "h1",
                                            {
                                              staticClass:
                                                "counts mb-4 font-weight-medium"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.dd14.value) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "periods" },
                                            [
                                              !_vm.dd14.positive
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "primary--text",
                                                      staticStyle: {
                                                        "font-size": "16px"
                                                      }
                                                    },
                                                    [_vm._v("mdi-minus")]
                                                  )
                                                : _vm._e(),
                                              _vm.dd14.positive
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "primary--text",
                                                      staticStyle: {
                                                        "font-size": "16px"
                                                      }
                                                    },
                                                    [_vm._v("mdi-plus")]
                                                  )
                                                : _vm._e(),
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.dd14.percent) +
                                                    " % "
                                                )
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  " / " +
                                                    _vm._s(
                                                      _vm.$t("overview.period")
                                                    )
                                                )
                                              ]),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    top: "",
                                                    "max-width": "300px"
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "ml-2 secondary--text",
                                                                  attrs: {
                                                                    small: ""
                                                                  }
                                                                },
                                                                "v-icon",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "mdi-help-circle"
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "overview.tooltip"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", lg: "2", sm: "4" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "pa-4 rounded-lg",
                                      attrs: { elevation: "3", outlined: "" }
                                    },
                                    [
                                      _c("v-select", {
                                        staticClass: "rounded-lg",
                                        attrs: {
                                          items: _vm.paramsDD15,
                                          required: "",
                                          outlined: "",
                                          dense: "",
                                          "item-text": "text",
                                          "item-value": "value",
                                          "return-object": ""
                                        },
                                        on: { change: _vm.changedd15 },
                                        model: {
                                          value: _vm.dd15.selected,
                                          callback: function($$v) {
                                            _vm.$set(_vm.dd15, "selected", $$v)
                                          },
                                          expression: "dd15.selected"
                                        }
                                      }),
                                      _c(
                                        "v-card-text",
                                        { staticClass: "pa-0 text-center" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-left grey--text"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.period.text) + " "
                                              ),
                                              _vm.dd15.displayCurr
                                                ? _c(
                                                    "strong",
                                                    {
                                                      staticStyle: {
                                                        float: "right",
                                                        color: "black"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " ( In " +
                                                          _vm._s(
                                                            _vm.dd15.curr
                                                          ) +
                                                          " )"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          ),
                                          _c(
                                            "h1",
                                            {
                                              staticClass:
                                                "counts mb-4 font-weight-medium"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.dd15.value) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "periods" },
                                            [
                                              !_vm.dd15.positive
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "primary--text",
                                                      staticStyle: {
                                                        "font-size": "16px"
                                                      }
                                                    },
                                                    [_vm._v("mdi-minus")]
                                                  )
                                                : _vm._e(),
                                              _vm.dd15.positive
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "primary--text",
                                                      staticStyle: {
                                                        "font-size": "16px"
                                                      }
                                                    },
                                                    [_vm._v("mdi-plus")]
                                                  )
                                                : _vm._e(),
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.dd15.percent) +
                                                    " % "
                                                )
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  " / " +
                                                    _vm._s(
                                                      _vm.$t("overview.period")
                                                    )
                                                )
                                              ]),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    top: "",
                                                    "max-width": "300px"
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "ml-2 secondary--text",
                                                                  attrs: {
                                                                    small: ""
                                                                  }
                                                                },
                                                                "v-icon",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "mdi-help-circle"
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "overview.tooltip"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", lg: "2", sm: "4" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "pa-4 rounded-lg",
                                      attrs: { elevation: "3", outlined: "" }
                                    },
                                    [
                                      _c("v-select", {
                                        staticClass: "rounded-lg",
                                        attrs: {
                                          items: _vm.paramsDD16,
                                          required: "",
                                          outlined: "",
                                          dense: "",
                                          "item-text": "text",
                                          "item-value": "value",
                                          "return-object": ""
                                        },
                                        on: { change: _vm.changedd16 },
                                        model: {
                                          value: _vm.dd16.selected,
                                          callback: function($$v) {
                                            _vm.$set(_vm.dd16, "selected", $$v)
                                          },
                                          expression: "dd16.selected"
                                        }
                                      }),
                                      _c(
                                        "v-card-text",
                                        { staticClass: "pa-0 text-center" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-left grey--text"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.period.text) + " "
                                              ),
                                              _vm.dd16.displayCurr
                                                ? _c(
                                                    "strong",
                                                    {
                                                      staticStyle: {
                                                        float: "right",
                                                        color: "black"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " ( In " +
                                                          _vm._s(
                                                            _vm.dd16.curr
                                                          ) +
                                                          " )"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          ),
                                          _c(
                                            "h1",
                                            {
                                              staticClass:
                                                "counts mb-4 font-weight-medium"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.dd16.value) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "periods" },
                                            [
                                              !_vm.dd16.positive
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "primary--text",
                                                      staticStyle: {
                                                        "font-size": "16px"
                                                      }
                                                    },
                                                    [_vm._v("mdi-minus")]
                                                  )
                                                : _vm._e(),
                                              _vm.dd16.positive
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "primary--text",
                                                      staticStyle: {
                                                        "font-size": "16px"
                                                      }
                                                    },
                                                    [_vm._v("mdi-plus")]
                                                  )
                                                : _vm._e(),
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.dd16.percent) +
                                                    " % "
                                                )
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  " / " +
                                                    _vm._s(
                                                      _vm.$t("overview.period")
                                                    )
                                                )
                                              ]),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    top: "",
                                                    "max-width": "300px"
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "ml-2 secondary--text",
                                                                  attrs: {
                                                                    small: ""
                                                                  }
                                                                },
                                                                "v-icon",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "mdi-help-circle"
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "overview.tooltip"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "pa-4 rounded-lg",
                                      attrs: { elevation: "3" }
                                    },
                                    [
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass: "pa-0",
                                            attrs: { flat: "" }
                                          },
                                          [
                                            _c("v-toolbar", {
                                              attrs: {
                                                flat: "",
                                                dense: "",
                                                prominent: "",
                                                height: "10"
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "extension",
                                                  fn: function() {
                                                    return [
                                                      _c(
                                                        "v-tabs",
                                                        {
                                                          model: {
                                                            value: _vm.tabs,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.tabs = $$v
                                                            },
                                                            expression: "tabs"
                                                          }
                                                        },
                                                        _vm._l(
                                                          _vm.tabItems,
                                                          function(item) {
                                                            return _c(
                                                              "v-tab",
                                                              { key: item },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ])
                                            }),
                                            _c(
                                              "v-tabs-items",
                                              {
                                                model: {
                                                  value: _vm.tabs,
                                                  callback: function($$v) {
                                                    _vm.tabs = $$v
                                                  },
                                                  expression: "tabs"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-tab-item",
                                                  [
                                                    _c(
                                                      "v-card",
                                                      {
                                                        staticClass: "pa-0",
                                                        attrs: { flat: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-card-title",
                                                          {
                                                            staticClass: "px-0"
                                                          },
                                                          [
                                                            _c(
                                                              "v-row",
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols:
                                                                        "12",
                                                                      sm: "6"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-sheet",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "white",
                                                                          elevation:
                                                                            "0"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-select",
                                                                          {
                                                                            staticClass:
                                                                              "rounded-lg",
                                                                            attrs: {
                                                                              items:
                                                                                _vm.params,
                                                                              required:
                                                                                "",
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "item-text":
                                                                                "text",
                                                                              "item-value":
                                                                                "value",
                                                                              "return-object":
                                                                                ""
                                                                            },
                                                                            on: {
                                                                              change:
                                                                                _vm.changedd22
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm
                                                                                  .dd22
                                                                                  .selected,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  _vm.dd22,
                                                                                  "selected",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "dd22.selected"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-card-text",
                                                          {
                                                            staticClass: "px-0"
                                                          },
                                                          [
                                                            _c("DeviceChart", {
                                                              attrs: {
                                                                height: 220,
                                                                deviceData:
                                                                  _vm.deviceData
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tab-item",
                                                  [
                                                    _c(
                                                      "v-card",
                                                      {
                                                        staticClass: "pa-0",
                                                        attrs: { flat: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-card-title",
                                                          {
                                                            staticClass: "px-0"
                                                          },
                                                          [
                                                            _c(
                                                              "v-row",
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols:
                                                                        "12",
                                                                      sm: "6"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-sheet",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "white",
                                                                          elevation:
                                                                            "0"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-select",
                                                                          {
                                                                            staticClass:
                                                                              "no-field-details small-select rounded-lg",
                                                                            attrs: {
                                                                              items:
                                                                                _vm.params,
                                                                              required:
                                                                                "",
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "item-text":
                                                                                "text",
                                                                              "item-value":
                                                                                "value",
                                                                              "return-object":
                                                                                ""
                                                                            },
                                                                            on: {
                                                                              change:
                                                                                _vm.changedd21
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm
                                                                                  .dd21
                                                                                  .selected,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  _vm.dd21,
                                                                                  "selected",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "dd21.selected"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-card-text",
                                                          {
                                                            staticClass: "px-0"
                                                          },
                                                          [
                                                            _c("GenderChart", {
                                                              attrs: {
                                                                height: 220,
                                                                genderData:
                                                                  _vm.genderData
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    ],
                                    2
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "pa-4 rounded-lg",
                                      attrs: { elevation: "3" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        { staticClass: "pt-0 px-0" },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "12", sm: "6" }
                                                },
                                                [
                                                  _c(
                                                    "v-sheet",
                                                    {
                                                      attrs: {
                                                        color: "white",
                                                        elevation: "0"
                                                      }
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        staticClass:
                                                          "rounded-lg",
                                                        attrs: {
                                                          items: _vm.params,
                                                          required: "",
                                                          outlined: "",
                                                          dense: "",
                                                          "item-text": "text",
                                                          "item-value": "value",
                                                          "return-object": ""
                                                        },
                                                        on: {
                                                          change: _vm.changedd23
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.dd23.selected,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.dd23,
                                                              "selected",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "dd23.selected"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-text",
                                        { staticClass: "px-0" },
                                        [
                                          _c("MapChart", {
                                            attrs: {
                                              countryData: _vm.locationData,
                                              highColor: "#57b12c",
                                              lowColor: "#b2dc9e",
                                              countryStrokeColor: "#909090",
                                              defaultCountryFillColor: "#dadada"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "pa-4 rounded-lg",
                                      attrs: { elevation: "3", height: "100%" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "subtitle-1 pt-0 font-weight-medium px-0 justify-center"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("overview.topKeywords")
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "v-sheet",
                                            {
                                              staticClass: "ml-2",
                                              attrs: {
                                                color: "white",
                                                elevation: "0",
                                                height: "40",
                                                width: "auto",
                                                "max-width": "150"
                                              }
                                            },
                                            [
                                              _c("v-select", {
                                                staticClass: "rounded-lg",
                                                attrs: {
                                                  items: _vm.paramsLimited,
                                                  required: "",
                                                  outlined: "",
                                                  dense: "",
                                                  "item-text": "text",
                                                  "item-value": "value",
                                                  "return-object": ""
                                                },
                                                on: { change: _vm.changedd31 },
                                                model: {
                                                  value: _vm.dd31.selected,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.dd31,
                                                      "selected",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "dd31.selected"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-text",
                                        { staticClass: "text-center pa-0" },
                                        [
                                          _c("v-simple-table", {
                                            staticClass: "bordered-table",
                                            attrs: { dense: "", light: "" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function() {
                                                  return [
                                                    _c("thead", [
                                                      _c("tr", [
                                                        _c(
                                                          "th",
                                                          {
                                                            staticClass:
                                                              "text-left",
                                                            attrs: {
                                                              id: "Keyword"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.key"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "th",
                                                          {
                                                            staticClass:
                                                              "text-left",
                                                            attrs: {
                                                              id: "Spend"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.spend"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "th",
                                                          {
                                                            staticClass:
                                                              "text-left",
                                                            attrs: {
                                                              id: "Impressions"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.impressions"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "th",
                                                          {
                                                            staticClass:
                                                              "text-left",
                                                            attrs: {
                                                              id: "Taps"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.taps"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    ]),
                                                    _vm.topKeywords.length > 0
                                                      ? _c(
                                                          "tbody",
                                                          _vm._l(
                                                            _vm.topKeywords,
                                                            function(item) {
                                                              return _c(
                                                                "tr",
                                                                {
                                                                  key: item.id
                                                                },
                                                                [
                                                                  item
                                                                    ? _c(
                                                                        "td",
                                                                        {
                                                                          staticClass:
                                                                            "text-left"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.name
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  item
                                                                    ? _c(
                                                                        "td",
                                                                        {
                                                                          staticClass:
                                                                            "text-left"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.localSpend
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  item
                                                                    ? _c(
                                                                        "td",
                                                                        {
                                                                          staticClass:
                                                                            "text-left"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.impressions
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  item
                                                                    ? _c(
                                                                        "td",
                                                                        {
                                                                          staticClass:
                                                                            "text-left"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.taps
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : _vm._e()
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-actions",
                                        { staticClass: "pa-0 pt-4" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                depressed: "",
                                                small: "",
                                                color: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.goToDashBoard(
                                                    "keywords"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("overview.goToKey")
                                                ) + " "
                                              ),
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("mdi-chevron-right")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "pa-4 rounded-lg",
                                      attrs: { elevation: "3", height: "100%" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "subtitle-1 font-weight-medium px-0 justify-center pt-0"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("overview.topAdgroup")
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "v-sheet",
                                            {
                                              staticClass: "ml-2",
                                              attrs: {
                                                color: "white",
                                                elevation: "0",
                                                height: "40",
                                                width: "auto",
                                                "max-width": "150"
                                              }
                                            },
                                            [
                                              _c("v-select", {
                                                staticClass: "rounded-lg",
                                                attrs: {
                                                  items: _vm.paramsLimited,
                                                  required: "",
                                                  outlined: "",
                                                  dense: "",
                                                  "item-text": "text",
                                                  "item-value": "value",
                                                  "return-object": ""
                                                },
                                                on: { change: _vm.changedd32 },
                                                model: {
                                                  value: _vm.dd32.selected,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.dd32,
                                                      "selected",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "dd32.selected"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-text",
                                        { staticClass: "text-center pa-0" },
                                        [
                                          _c("v-simple-table", {
                                            staticClass: "bordered-table",
                                            attrs: { dense: "" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function() {
                                                  return [
                                                    _c("thead", [
                                                      _c("tr", [
                                                        _c(
                                                          "th",
                                                          {
                                                            staticClass:
                                                              "text-left",
                                                            attrs: {
                                                              id: "AdGroup"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.adgroup"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "th",
                                                          {
                                                            staticClass:
                                                              "text-left",
                                                            attrs: {
                                                              id: "Spend"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.spend"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "th",
                                                          {
                                                            staticClass:
                                                              "text-left",
                                                            attrs: {
                                                              id: "Impressions"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.impressions"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "th",
                                                          {
                                                            staticClass:
                                                              "text-left",
                                                            attrs: {
                                                              id: "Taps"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.taps"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    ]),
                                                    _vm.topAdgroups.length > 0
                                                      ? _c(
                                                          "tbody",
                                                          _vm._l(
                                                            _vm.topAdgroups,
                                                            function(item) {
                                                              return _c(
                                                                "tr",
                                                                {
                                                                  key: item.id
                                                                },
                                                                [
                                                                  item
                                                                    ? _c(
                                                                        "td",
                                                                        {
                                                                          staticClass:
                                                                            "text-left"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.name
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  item
                                                                    ? _c(
                                                                        "td",
                                                                        {
                                                                          staticClass:
                                                                            "text-left"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.localSpend
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  item
                                                                    ? _c(
                                                                        "td",
                                                                        {
                                                                          staticClass:
                                                                            "text-left"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.impressions
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  item
                                                                    ? _c(
                                                                        "td",
                                                                        {
                                                                          staticClass:
                                                                            "text-left"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.taps
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : _vm._e()
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-actions",
                                        { staticClass: "pa-0 pt-4" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                depressed: "",
                                                small: "",
                                                color: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.goToDashBoard(
                                                    "adgroups"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("overview.goToAdgroup")
                                                ) + " "
                                              ),
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("mdi-chevron-right")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.showCL
                        ? _c(
                            "div",
                            { staticClass: "col-right-main" },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "mx-auto",
                                  attrs: { "max-width": "466", elevation: "1" }
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    [
                                      _vm._v("Checklist "),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { elevation: "0", icon: "" },
                                          on: {
                                            click: function($event) {
                                              _vm.showCL = false
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              attrs: {
                                                width: "16",
                                                height: "16",
                                                viewBox: "0 0 16 16",
                                                fill: "none",
                                                xmlns:
                                                  "http://www.w3.org/2000/svg"
                                              }
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  d:
                                                    "M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z",
                                                  fill: "black"
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "titleText" },
                                        [
                                          _c("v-icon", {
                                            staticClass: "mr-2",
                                            attrs: { color: _vm.color0 },
                                            domProps: {
                                              textContent: _vm._s(_vm.check0)
                                            }
                                          }),
                                          _c(
                                            "a",
                                            {
                                              attrs: { href: "/integrations" }
                                            },
                                            [_vm._v(" Integrations ")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-icon", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  size: "15",
                                                  color: _vm.color1
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.check1
                                                  )
                                                }
                                              }),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "/integrations"
                                                  }
                                                },
                                                [_vm._v(" Apple Search Ads")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-icon", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  size: "15",
                                                  color: _vm.color6
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.check6
                                                  )
                                                }
                                              }),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "/integrations"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " Add Slack Integration"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider", { staticClass: "my-2" }),
                                      _c(
                                        "div",
                                        { staticClass: "titleText" },
                                        [
                                          _c("v-icon", {
                                            staticClass: "mr-2",
                                            attrs: { color: _vm.color11 },
                                            domProps: {
                                              textContent: _vm._s(_vm.check11)
                                            }
                                          }),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "/keyword-auction-insights"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " Become familiar with Keyword Analysis Tools"
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-icon", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  color: _vm.color2,
                                                  size: "15"
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.check2
                                                  )
                                                }
                                              }),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "/keyword-auction-insights?tab=comp"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " See how your competitors are doing."
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-icon", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  color: _vm.color7,
                                                  size: "15"
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.check7
                                                  )
                                                }
                                              }),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "/keyword-auction-insights?tab=recom"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " See the Recommended Keywords."
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-icon", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  color: _vm.color8,
                                                  size: "15"
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.check8
                                                  )
                                                }
                                              }),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "/keyword-auction-insights?tab=app"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " See Keywords for an App."
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-icon", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  color: _vm.color9,
                                                  size: "15"
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.check9
                                                  )
                                                }
                                              }),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "/keyword-auction-insights?tab=key"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " See Bidding Apps for a keyword."
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-icon", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  color: _vm.color10,
                                                  size: "15"
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.check10
                                                  )
                                                }
                                              }),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "/keyword-auction-insights?tab=top"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " See category top charts."
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider", { staticClass: "my-2" }),
                                      _c(
                                        "div",
                                        { staticClass: "titleText" },
                                        [
                                          _c("v-icon", {
                                            staticClass: "mr-2",
                                            domProps: {
                                              textContent: _vm._s(_vm.check16)
                                            }
                                          }),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "/create-campaign"
                                              }
                                            },
                                            [_vm._v("Campaign")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-icon", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  color: _vm.color12,
                                                  size: "15"
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.check12
                                                  )
                                                }
                                              }),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "/create-campaign"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " Initiate a Campaign."
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-icon", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  color: _vm.color13,
                                                  size: "15"
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.check13
                                                  )
                                                }
                                              }),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "/create-campaign"
                                                  }
                                                },
                                                [_vm._v(" Create Adgroup.")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-icon", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  color: _vm.color14,
                                                  size: "15"
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.check14
                                                  )
                                                }
                                              }),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "/create-campaign"
                                                  }
                                                },
                                                [_vm._v(" Add keywords.")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-icon", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  color: _vm.color15,
                                                  size: "15"
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.check15
                                                  )
                                                }
                                              }),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "/create-campaign"
                                                  }
                                                },
                                                [_vm._v(" Add Creative Set.")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-icon", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  color: _vm.color3,
                                                  size: "15"
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.check3
                                                  )
                                                }
                                              }),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "/ads-manager"
                                                  }
                                                },
                                                [_vm._v(" Go Live.")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider", { staticClass: "my-2" }),
                                      _c(
                                        "div",
                                        { staticClass: "titleText" },
                                        [
                                          _c("v-icon", {
                                            staticClass: "mr-2",
                                            attrs: { color: _vm.color5 },
                                            domProps: {
                                              textContent: _vm._s(_vm.check5)
                                            }
                                          }),
                                          _c(
                                            "a",
                                            { attrs: { href: "/overview" } },
                                            [
                                              _vm._v(
                                                " Review campaign success."
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider", { staticClass: "my-2" }),
                                      _c(
                                        "div",
                                        { staticClass: "titleText" },
                                        [
                                          _c("v-icon", {
                                            staticClass: "mr-2",
                                            attrs: { color: _vm.color4 },
                                            domProps: {
                                              textContent: _vm._s(_vm.check4)
                                            }
                                          }),
                                          _c(
                                            "a",
                                            { attrs: { href: "/arrules" } },
                                            [_vm._v(" Automate your campaign.")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              ],
              2
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }