<template flex>
  <div class="dashboard">
    <v-app>
      <div class="inner-container">
        <!-- Provides the application the proper gutter -->
        <div class="top-page-header">
          <div class="head-title">
            <h1>
              {{ $t('pages.overview') }}
            </h1>
          </div>

          <v-row class="select-area">
            <v-col lg="3" md="4" sm="6" cols="12">
              <v-sheet
                color="white"
                elevation="0"
              >
                <v-select
                  class="rounded-lg"
                  v-model="searchApp"
                  :items="searchedApps"
                  :loading="loadingApps"
                  label="Search App"
                  item-text="appName"
                  item-value="adamId"
                  required
                  outlined
                  dense
                  prepend-inner-icon="mdi-cellphone"
                  return-object
                  @change="changeApp"
                ></v-select>
              </v-sheet>
            </v-col>

            <v-col lg="3" md="4" sm="6" cols="12">
              <v-sheet
                color="white"
                elevation="0"
              >
                <v-select
                  class="rounded-lg"
                  v-model="period"
                  :items="periods"
                  label="Select period"
                  required
                  outlined
                  dense
                  prepend-inner-icon="mdi-calendar"
                  v-on:change="changePeriod"
                  item-text="text"
                  item-value="value"
                  return-object
                ></v-select>
              </v-sheet>
            </v-col>
          </v-row>
        </div>

        <v-container fluid class="v-main-content">
          <template fluid>

          <div class="colFullOverview">
            <!-- v-show="!hidden" -->
              <v-btn
                color="primary"
                class="floatingBtn rounded-r-xl-0 rounded-l-xl"
                elevation="0"
                dark
                absolute
                top
                right
                @click="showCL = ~ showCL"
                v-if="existCL"
              >
                <v-icon>mdi-check-circle</v-icon>
              </v-btn>

            <div class="col-left-main">
              <v-row class="v-fixed-top-section">

                <v-col cols="12" lg="2" sm="4">
                  <v-card elevation="3" class="pa-4 rounded-lg">
                    <v-select
                      class="rounded-lg"
                      v-model="dd11.selected"
                      :items="paramsDD11"
                      required
                      outlined
                      dense
                      @change="changedd11"
                      item-text="text"
                      item-value="value"
                      return-object
                    ></v-select>
                    <v-card-text class="pa-0 text-center">
                      <p class="text-left grey--text">{{ period.text }} <strong v-if="dd11.displayCurr" style="float: right;color: black;"> ( In {{ dd11.curr }} )</strong></p>
                      <h1 class="counts mb-4 font-weight-medium">
                        {{ dd11.value }}
                      </h1>
                      <div class="periods">
                        <v-icon v-if="!dd11.positive" style="font-size: 16px" class="primary--text">mdi-minus</v-icon
                        ><v-icon v-if="dd11.positive" style="font-size: 16px" class="primary--text">mdi-plus</v-icon
                        ><span> {{ dd11.percent }} % </span
                        ><span> / {{ $t('overview.period') }}</span
                        ><v-tooltip top max-width="300px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon class="ml-2 secondary--text" small v-bind="attrs" v-on="on"
                              >mdi-help-circle</v-icon
                            >
                          </template>
                          <span>{{ $t('overview.tooltip') }}</span>
                        </v-tooltip>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" lg="2" sm="4">
                  <v-card elevation="3" class="pa-4 rounded-lg">
                    <v-select
                      class="rounded-lg"
                      v-model="dd12.selected"
                      :items="paramsDD12"
                      required
                      outlined
                      dense
                      @change="changedd12"
                      item-text="text"
                      item-value="value"
                      return-object
                    ></v-select>
                    <v-card-text class="pa-0 text-center">
                      <p class="text-left grey--text">{{ period.text }} <strong v-if="dd12.displayCurr" style="float: right;color: black;"> ( In {{ dd12.curr }} )</strong></p>
                      <h1
                        class="counts mb-4 font-weight-medium"
                      >
                        {{ dd12.value }}
                      </h1>
                      <div class="periods">
                        <v-icon v-if="!dd12.positive" style="font-size: 16px" class="primary--text">mdi-minus</v-icon
                        ><v-icon v-if="dd12.positive" style="font-size: 16px" class="primary--text">mdi-plus</v-icon
                        ><span> {{ dd12.percent }} %</span
                        ><span> / {{ $t('overview.period') }}</span>
                        <v-tooltip top max-width="300px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon class="ml-2 secondary--text" small v-bind="attrs" v-on="on"
                              >mdi-help-circle</v-icon
                            >
                          </template>
                          <span>{{ $t('overview.tooltip') }}</span>
                        </v-tooltip>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" lg="2" sm="4">
                  <v-card elevation="3" class="pa-4 rounded-lg">
                    <v-select
                      class="rounded-lg"
                      v-model="dd13.selected"
                      :items="paramsDD13"
                      required
                      outlined
                      dense
                      @change="changedd13"
                      item-text="text"
                      item-value="value"
                      return-object
                    ></v-select>
                    <v-card-text class="pa-0 text-center">
                      <p class="text-left grey--text">{{ period.text }} <strong v-if="dd13.displayCurr" style="float: right;color: black;"> ( In {{ dd13.curr }} )</strong></p>
                      <h1
                        class="counts mb-4 font-weight-medium"
                      >
                        {{ dd13.value }}
                      </h1>
                      <div class="periods">
                        <v-icon v-if="!dd13.positive" style="font-size: 16px" class="primary--text">mdi-minus</v-icon
                        ><v-icon v-if="dd13.positive" style="font-size: 16px" class="primary--text">mdi-plus</v-icon
                        ><span> {{ dd13.percent }} % </span
                        ><span> / {{ $t('overview.period') }}</span
                        ><v-tooltip top max-width="300px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon class="ml-2 secondary--text" small v-bind="attrs" v-on="on"
                              >mdi-help-circle</v-icon
                            >
                          </template>
                          <span>{{ $t('overview.tooltip') }}</span>
                        </v-tooltip>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" lg="2" sm="4">
                  <v-card elevation="3" outlined class="pa-4 rounded-lg">
                    <v-select
                      class="rounded-lg"
                      v-model="dd14.selected"
                      :items="paramsDD14"
                      required
                      outlined
                      dense
                      @change="changedd14"
                      item-text="text"
                      item-value="value"
                      return-object
                    ></v-select>
                    <v-card-text class="pa-0 text-center">
                      <p class="text-left grey--text">{{ period.text }} <strong v-if="dd14.displayCurr" style="float: right;color: black;"> ( In {{ dd14.curr }} )</strong></p>
                      <h1
                        class="counts mb-4 font-weight-medium"
                      >
                        {{ dd14.value }}
                      </h1>
                      <div class="periods">
                        <v-icon v-if="!dd14.positive" style="font-size: 16px" class="primary--text">mdi-minus</v-icon
                        ><v-icon v-if="dd14.positive" style="font-size: 16px" class="primary--text">mdi-plus</v-icon
                        ><span> {{ dd14.percent }} % </span
                        ><span> / {{ $t('overview.period') }}</span
                        ><v-tooltip top max-width="300px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon class="ml-2 secondary--text" small v-bind="attrs" v-on="on"
                              >mdi-help-circle</v-icon
                            >
                          </template>
                          <span>{{ $t('overview.tooltip') }}</span>
                        </v-tooltip>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" lg="2" sm="4">
                  <v-card elevation="3" outlined class="pa-4 rounded-lg">
                    <v-select
                      class="rounded-lg"
                      v-model="dd15.selected"
                      :items="paramsDD15"
                      required
                      outlined
                      dense
                      @change="changedd15"
                      item-text="text"
                      item-value="value"
                      return-object
                    ></v-select>
                    <v-card-text class="pa-0 text-center">
                      <p class="text-left grey--text">{{ period.text }} <strong v-if="dd15.displayCurr" style="float: right;color: black;"> ( In {{ dd15.curr }} )</strong></p>
                      <h1
                        class="counts mb-4 font-weight-medium"
                      >
                        {{ dd15.value }}
                      </h1>
                      <div class="periods">
                        <v-icon v-if="!dd15.positive" style="font-size: 16px" class="primary--text">mdi-minus</v-icon
                        ><v-icon v-if="dd15.positive" style="font-size: 16px" class="primary--text">mdi-plus</v-icon
                        ><span> {{ dd15.percent }} % </span
                        ><span> / {{ $t('overview.period') }}</span
                        ><v-tooltip top max-width="300px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon class="ml-2 secondary--text" small v-bind="attrs" v-on="on"
                              >mdi-help-circle</v-icon
                            >
                          </template>
                          <span>{{ $t('overview.tooltip') }}</span>
                        </v-tooltip>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" lg="2" sm="4">
                  <v-card elevation="3" outlined class="pa-4 rounded-lg">
                    <v-select
                      class="rounded-lg"
                      v-model="dd16.selected"
                      :items="paramsDD16"
                      required
                      outlined
                      dense
                      @change="changedd16"
                      item-text="text"
                      item-value="value"
                      return-object
                    ></v-select>
                    <v-card-text class="pa-0 text-center">
                      <p class="text-left grey--text">{{ period.text }} <strong v-if="dd16.displayCurr" style="float: right;color: black;"> ( In {{ dd16.curr }} )</strong></p>
                      <h1
                        class="counts mb-4 font-weight-medium"
                      >
                        {{ dd16.value }}
                      </h1>
                      <div class="periods">
                        <v-icon v-if="!dd16.positive" style="font-size: 16px" class="primary--text">mdi-minus</v-icon
                        ><v-icon v-if="dd16.positive" style="font-size: 16px" class="primary--text">mdi-plus</v-icon
                        ><span> {{ dd16.percent }} % </span
                        ><span> / {{ $t('overview.period') }}</span
                        ><v-tooltip top max-width="300px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon class="ml-2 secondary--text" small v-bind="attrs" v-on="on"
                              >mdi-help-circle</v-icon
                            >
                          </template>
                          <span>{{ $t('overview.tooltip') }}</span>
                        </v-tooltip>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>

              </v-row>

              <v-row>

                <v-col cols="12" sm="6">
                  <v-card class="pa-4 rounded-lg" elevation="3">
                    <template>
                      <v-card class="pa-0" flat>
                        <v-toolbar flat dense prominent height="10">
                          <template v-slot:extension>
                            <v-tabs v-model="tabs">
                              <v-tab v-for="item in tabItems" :key="item">
                                {{ item }}
                              </v-tab>
                            </v-tabs>
                          </template>
                        </v-toolbar>

                        <v-tabs-items v-model="tabs">
                          <v-tab-item>
                            <v-card flat class="pa-0">
                              <v-card-title class="px-0">
                                <v-row>
                                  <v-col cols="12" sm="6">
                                    <v-sheet
                                      color="white"
                                      elevation="0"
                                    >
                                      <v-select
                                        class="rounded-lg"
                                        v-model="dd22.selected"
                                        :items="params"
                                        required
                                        outlined
                                        dense
                                        @change="changedd22"
                                        item-text="text"
                                        item-value="value"
                                        return-object
                                      ></v-select>
                                    </v-sheet>
                                  </v-col>
                                </v-row>
                              </v-card-title>
                              <v-card-text class="px-0">
                                <DeviceChart
                                  :height="220"
                                  :deviceData="deviceData"
                                />
                              </v-card-text>
                            </v-card>
                          </v-tab-item>
                          <v-tab-item>
                            <v-card flat class="pa-0">
                              <v-card-title class="px-0">
                                <v-row>
                                  <v-col cols="12" sm="6">
                                    <v-sheet
                                      color="white"
                                      elevation="0"
                                    >
                                      <v-select
                                        class="no-field-details small-select rounded-lg"
                                        v-model="dd21.selected"
                                        :items="params"
                                        required
                                        outlined
                                        dense
                                        @change="changedd21"
                                        item-text="text"
                                        item-value="value"
                                        return-object
                                      ></v-select>
                                    </v-sheet>
                                  </v-col>
                                </v-row>
                              </v-card-title>
                              <v-card-text class="px-0">
                                <GenderChart
                                  :height="220"
                                  :genderData="genderData"
                                />
                              </v-card-text>
                            </v-card>
                          </v-tab-item>
                        </v-tabs-items>
                      </v-card>
                    </template>
                  </v-card>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-card class="pa-4 rounded-lg" elevation="3">
                    <v-card-title class="pt-0 px-0">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-sheet
                            color="white"
                            elevation="0"
                          >
                            <v-select
                              class="rounded-lg"
                              v-model="dd23.selected"
                              :items="params"
                              required
                              outlined
                              dense
                              @change="changedd23"
                              item-text="text"
                              item-value="value"
                              return-object
                            ></v-select>

                          </v-sheet>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text class="px-0">
                      <MapChart
                        :countryData="locationData"
                        highColor="#57b12c"
                        lowColor="#b2dc9e"
                        countryStrokeColor="#909090"
                        defaultCountryFillColor="#dadada"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row>

                <v-col cols="12" sm="6">
                  <v-card class="pa-4 rounded-lg" elevation="3" height="100%">
                    <v-card-title
                      class="subtitle-1 pt-0 font-weight-medium px-0 justify-center"
                    >
                      {{ $t('overview.topKeywords') }}
                      <v-sheet
                        color="white"
                        elevation="0"
                        height="40"
                        width="auto"
                        max-width="150"
                        class="ml-2"
                      >
                        <v-select
                          class="rounded-lg"
                          v-model="dd31.selected"
                          :items="paramsLimited"
                          required
                          outlined
                          dense
                          @change="changedd31"
                          item-text="text"
                          item-value="value"
                          return-object
                        ></v-select>
                      </v-sheet>
                    </v-card-title>

                    <v-card-text class="text-center pa-0">
                      <v-simple-table dense light class="bordered-table">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th id="Keyword" class="text-left">
                                {{ $t('overview.key') }}
                              </th>
                              <th id="Spend" class="text-left">
                                {{ $t('overview.spend') }}
                              </th>
                              <th id="Impressions" class="text-left">
                                {{ $t('overview.impressions') }}
                              </th>
                              <th id="Taps" class="text-left">
                                {{ $t('overview.taps') }}
                              </th>
                            </tr>
                          </thead>
                          <tbody v-if="topKeywords.length > 0">
                            <tr v-for="item in topKeywords" :key="item.id">
                              <td v-if="item" class="text-left">
                                {{ item.name }}
                              </td>
                              <td v-if="item" class="text-left">
                                {{ item.localSpend }}
                              </td>
                              <td v-if="item" class="text-left">
                                {{ item.impressions }}
                              </td>
                              <td v-if="item" class="text-left">
                                {{ item.taps }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>

                    <v-card-actions class="pa-0 pt-4"
                      ><v-btn
                        depressed
                        small
                        color="primary"
                        @click="goToDashBoard('keywords')"
                        >{{ $t('overview.goToKey') }}
                        <v-icon small>mdi-chevron-right</v-icon></v-btn
                      ></v-card-actions
                    >
                  </v-card>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-card class="pa-4 rounded-lg" elevation="3" height="100%">
                    <v-card-title
                      class="subtitle-1 font-weight-medium px-0 justify-center pt-0"
                    >
                      {{ $t('overview.topAdgroup') }}
                      <v-sheet
                        color="white"
                        elevation="0"
                        height="40"
                        width="auto"
                        max-width="150"
                        class="ml-2"
                      >
                        <v-select
                          class="rounded-lg"
                          v-model="dd32.selected"
                          :items="paramsLimited"
                          required
                          outlined
                          dense
                          @change="changedd32"
                          item-text="text"
                          item-value="value"
                          return-object
                        ></v-select>
                      </v-sheet>
                    </v-card-title>

                    <v-card-text class="text-center pa-0">
                      <v-simple-table dense class="bordered-table">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th id="AdGroup" class="text-left">
                                {{ $t('overview.adgroup') }}
                              </th>
                              <th id="Spend" class="text-left">
                                {{ $t('overview.spend') }}
                              </th>
                              <th id="Impressions" class="text-left">
                                {{ $t('overview.impressions') }}
                              </th>
                              <th id="Taps" class="text-left">
                                {{ $t('overview.taps') }}
                              </th>
                            </tr>
                          </thead>
                          <tbody v-if="topAdgroups.length > 0">
                            <tr v-for="item in topAdgroups" :key="item.id">
                              <td v-if="item" class="text-left">
                                {{ item.name }}
                              </td>
                              <td v-if="item" class="text-left">
                                {{ item.localSpend }}
                              </td>
                              <td v-if="item" class="text-left">
                                {{ item.impressions }}
                              </td>
                              <td v-if="item" class="text-left">
                                {{ item.taps }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>

                    <v-card-actions class="pa-0 pt-4"
                      ><v-btn
                        depressed
                        small
                        color="primary"
                        @click="goToDashBoard('adgroups')"
                        >{{ $t('overview.goToAdgroup') }}
                        <v-icon small>mdi-chevron-right</v-icon></v-btn
                      ></v-card-actions
                    >
                  </v-card>
                </v-col>

              </v-row>
            </div>

            <div class="col-right-main" v-if="showCL">
                <v-card
                  class="mx-auto"
                  max-width="466"
                  elevation="1"
                >
                  <v-card-title>Checklist <v-btn elevation="0" icon @click="showCL = false"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z" fill="black"/>
                    </svg>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <div class="titleText"><v-icon :color="color0" class="mr-2" v-text="check0"></v-icon><a href="/integrations"> Integrations </a></div>
                    <v-list-item>
                      <v-list-item-content>
                        <v-icon size="15" :color="color1" class="mr-2" v-text="check1"></v-icon><a href="/integrations"> Apple Search Ads</a>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-icon size="15" :color="color6" class="mr-2" v-text="check6"></v-icon><a href="/integrations"> Add Slack Integration</a>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="my-2"></v-divider>
                    <!-- <div class="titleText"><v-icon class="mr-2" v-text="check6"></v-icon><a href="/integrations"> Add Slack Integration</a></div>
                    <v-divider class="my-2"></v-divider> -->
                    <div class="titleText"><v-icon :color="color11" class="mr-2" v-text="check11"></v-icon><a href="/keyword-auction-insights"> Become familiar with Keyword Analysis Tools</a></div>
                    <v-list-item>
                      <v-list-item-content>
                        <v-icon class="mr-2" :color="color2" size="15" v-text="check2"></v-icon><a href="/keyword-auction-insights?tab=comp"> See how your competitors are doing.</a>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-icon class="mr-2" :color="color7" size="15" v-text="check7"></v-icon><a href="/keyword-auction-insights?tab=recom"> See the Recommended Keywords.</a>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-icon class="mr-2" :color="color8" size="15" v-text="check8"></v-icon><a href="/keyword-auction-insights?tab=app"> See Keywords for an App.</a>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-icon class="mr-2" :color="color9" size="15" v-text="check9"></v-icon><a href="/keyword-auction-insights?tab=key"> See Bidding Apps for a keyword.</a>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-icon class="mr-2" :color="color10" size="15" v-text="check10"></v-icon><a href="/keyword-auction-insights?tab=top"> See category top charts.</a>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="my-2"></v-divider>
                    <!-- <div class="titleText"><v-icon class="mr-2" v-text="check12"></v-icon><a href="/create-campaign">Create your first campaign.</a></div>
                    <v-divider class="my-2"></v-divider> -->
                    <div class="titleText"><v-icon class="mr-2" v-text="check16"></v-icon><a href="/create-campaign">Campaign</a></div>
                    <v-list-item>
                      <v-list-item-content>
                        <v-icon class="mr-2" :color="color12" size="15" v-text="check12"></v-icon><a href="/create-campaign"> Initiate a Campaign.</a>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-icon class="mr-2" :color="color13" size="15" v-text="check13"></v-icon><a href="/create-campaign"> Create Adgroup.</a>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-icon class="mr-2" :color="color14" size="15" v-text="check14"></v-icon><a href="/create-campaign"> Add keywords.</a>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-icon class="mr-2" :color="color15" size="15" v-text="check15"></v-icon><a href="/create-campaign"> Add Creative Set.</a>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-icon class="mr-2" :color="color3" size="15" v-text="check3"></v-icon><a href="/ads-manager"> Go Live.</a>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="my-2"></v-divider>
                    <div class="titleText"><v-icon class="mr-2" :color="color5" v-text="check5"></v-icon><a href="/overview"> Review campaign success.</a></div>
                    <v-divider class="my-2"></v-divider>
                    <div class="titleText"><v-icon class="mr-2" :color="color4" v-text="check4"></v-icon><a href="/arrules"> Automate your campaign.</a></div>
                  </v-card-text>
                </v-card>
            </div>

          </div>
          </template>
        </v-container>
      </div>
    </v-app>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  APP_OVERVIEW,
  APP_OVERVIEW_ALONE,
  APP_OVERVIEW_MAP,
  APP_OVERVIEW_DEVICE,
  OWN_APPS,
  TOP_ADS,
  TOP_KEYS,
  CHECK_APP_ATTR,
} from '../utils/apptweakqueries';
import GenderChart from '../components/overview/GenderChart';
import DeviceChart from '../components/overview/DeviceChart';
import MapChart from 'vue-map-chart';
import { USER_DETAIL_BY_ID } from 'src/utils/apollo-queries';
import { LSS } from '@core/services';
import { decodeToken } from '@shared/utils/commom.util';
import { updateCLF } from '../utils/util'
export default Vue.extend({
  name: 'Overview',
  components: {
    GenderChart,
    DeviceChart,
    MapChart,
  },
  data() {
    return {
      existAttr: true,
      existCL: false,
      checkedIcon: 'mdi-check-circle',
      uncheckedIcon: 'mdi-checkbox-blank-circle-outline',
      check0: 'mdi-checkbox-blank-circle-outline',
      check1: 'mdi-checkbox-blank-circle-outline',
      check2: 'mdi-checkbox-blank-circle-outline',
      check3: 'mdi-checkbox-blank-circle-outline',
      check4: 'mdi-checkbox-blank-circle-outline',
      check5: 'mdi-checkbox-blank-circle-outline',
      check6: 'mdi-checkbox-blank-circle-outline',
      check7: 'mdi-checkbox-blank-circle-outline',
      check8: 'mdi-checkbox-blank-circle-outline',
      check9: 'mdi-checkbox-blank-circle-outline',
      check10: 'mdi-checkbox-blank-circle-outline',
      check11: 'mdi-checkbox-blank-circle-outline',
      check12: 'mdi-checkbox-blank-circle-outline',
      check13: 'mdi-checkbox-blank-circle-outline',
      check14: 'mdi-checkbox-blank-circle-outline',
      check15: 'mdi-checkbox-blank-circle-outline',
      check16: 'mdi-checkbox-blank-circle-outline',
      color0: '',
      color1: '',
      color7: '',
      color2: '',
      color6: '',
      color3: '',
      color4: '',
      color5: '',
      color8: '',
      color9: '',
      color10: '',
      color11: '',
      color12: '',
      color13: '',
      color14: '',
      color15: '',
      color16: '',
      showCL: false,
      mapData: {},
      genderData: {},
      deviceData: {},
      locationData: {},
      tabs: null,
      //tabItems: [this.$t('overview.gender'), this.$t('overview.device')],
      text:
        'Lorem ipsum dolor sit amet, consectetur. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',

      drawer: false,
      mini: true,
      e1: 1,
      dd11: {
        selected: {
          text: this.$t('overview.impressions'),
          value: 'impressions',
        },
        value: 0,
        percent: 0,
        positive: true,
      },
      dd12: {
        selected: {
          text: this.$t('overview.taps'),
          value: 'taps',
        },
        value: 0,
        percent: 0,
        positive: true,
      },
      dd13: {
        selected: {
          text: this.$t('overview.installs'),
          value: 'installs',
        },
        value: 0,
        percent: 0,
        positive: true,
      },
      dd14: {
        selected: {
          text: this.$t('overview.spend'),
          value: 'localSpend',
        },
        value: 0,
        percent: 0,
        positive: true,
      },
      dd15: {
        selected: {
          text: this.$t('overview.avgCPA'),
          value: 'avgCPA',
        },
        value: 0,
        percent: 0,
        positive: true,
      },
      dd16: {
        selected: {
          text: this.$t('overview.avgCPT'),
          value: 'avgCPT',
        },
        value: 0,
        percent: 0,
        positive: true,
      },
      dd21: {
        selected: {
          text: this.$t('overview.impressions'),
          value: 'impressions',
        },
        value: 0,
        percent: 0,
        positive: true,
      },
      dd22: {
        selected: {
          text: this.$t('overview.installs'),
          value: 'installs',
        },
        value: 0,
        percent: 0,
        positive: true,
      },
      dd23: {
        selected: {
          text: this.$t('overview.installs'),
          value: 'installs',
        },
        value: 0,
        percent: 0,
        positive: true,
      },
      dd31: {
        selected: {
          text: this.$t('overview.spend'),
          value: 'localSpend',
        },
        value: 0,
        percent: 0,
        positive: true,
      },
      dd32: {
        selected: {
          text: this.$t('overview.spend'),
          value: 'localSpend',
        },
        value: 0,
        percent: 0,
        positive: true,
      },

      topAdgroups: [],
      topKeywords: [],

      loading: true,
      modal: false,
      items: [],
      menuitems: [],
      search: '',
      loadingApps: false,
      searchedApps: [],
      selectedApp: '',
      currOverview: null,
      prevOverview: null,
      tempApps: [],
      fullscreenLoader: null,
      fullscreenLoaderAlone: null,
      fullscreenLoaderGender: null,
      fullscreenLoaderDevice: null,
      fullscreenLoaderMap: null,
      paramsDD11Temp: [],
      paramsDD12Temp: [],
      paramsDD13Temp: [],
      paramsDD14Temp: [],
      paramsDD15Temp: [],
      paramsDD16Temp: [],
    };
  },
  computed: {
    tabItems: {
      get() {
        return [this.$t('overview.device'),this.$t('overview.gender')];
      },
    },
    apps: {
      get() {
        return [this.$t('overview.searchApp')];
      },
    },
    days: {
      get() {
        return [
          this.$t('overview.lastDay'),
          this.$t('overview.last7Days'),
          this.$t('overview.last30Days'),
        ];
      },
    },
    KeywordsBy: {
      get() {
        return [
          this.$t('overview.spend'),
          this.$t('overview.impressions'),
          this.$t('overview.taps'),
        ];
      },
    },
    adGroupsBy: {
      get() {
        return [
          this.$t('overview.spend'),
          this.$t('overview.impressions'),
          this.$t('overview.taps'),
        ];
      },
    },
    paramsLimited: {
      get() {
        return [
          {
            text: this.$t('overview.impressions'),
            value: 'impressions',
          },
          {
            text: this.$t('overview.taps'),
            value: 'taps',
          },
          {
            text: this.$t('overview.spend'),
            value: 'localSpend',
          },
        ];
      },
      set(e) {
        this.paramsLimited.text = e.text
        this.paramsLimited.value = e.value
      }
    },
    paramsFull: {
      get() {
        return [
          {
            'header': 'ASA Fields',
            'divider': true
          },
          {
            text: this.$t('overview.impressions'),
            value: 'impressions',
          },
          {
            text: this.$t('overview.taps'),
            value: 'taps',
          },
          {
            text: this.$t('overview.installs'),
            value: 'installs',
          },
          {
            text: this.$t('overview.spend'),
            value: 'localSpend',
          },
          {
            text: this.$t('overview.ttr'),
            value: 'ttr',
          },
          {
            text: this.$t('overview.avgCPA'),
            value: 'avgCPA',
          },
          {
            text: this.$t('overview.avgCPT'),
            value: 'avgCPT',
          },
          {
            text: this.$t('overview.conversionRate'),
            value: 'conversionRate',
          },
          {
            text: this.$t('overview.latOnInstalls'),
            value: 'latOnInstalls',
          },
          {
            text: this.$t('overview.latOffInstalls'),
            value: 'latOffInstalls',
          },
          {
            text: this.$t('overview.reDownloads'),
            value: 'redownloads',
          },
          {
            text: this.$t('overview.newDownloads'),
            value: 'newDownloads',
          },
          {
            'header': 'Attribution Fields',
            'divider': true
          },
          {
            text: this.$t('overview.revenue'),
            value: 'revenue',
            disabled: this.existAttr ? true : false
          },
          {
            text: this.$t('overview.attrInstalls'),
            value: 'attrInstalls',
            disabled: this.existAttr
          },
          {
            text: this.$t('overview.unAttrInstalls'),
            value: 'unAttrInstalls',
            disabled: this.existAttr
          },
          {
            text: this.$t('overview.attrInstallRate'),
            value: 'attrInstallRate',
            disabled: this.existAttr
          },
          {
            text: this.$t('overview.events'),
            value: 'events',
            disabled: this.existAttr
          },
          {
            text: this.$t('overview.clicks'),
            value: 'clicks',
            disabled: this.existAttr
          },
          {
            text: this.$t('overview.cpi'),
            value: 'cpi',
            disabled: this.existAttr
          },
          {
            text: this.$t('overview.cpge'),
            value: 'cpge',
            disabled: this.existAttr
          },
          {
            text: this.$t('overview.roas'),
            value: 'roas',
            disabled: this.existAttr
          },
          {
            text: this.$t('overview.roi'),
            value: 'roi',
            disabled: this.existAttr
          },
          {
            text: this.$t('overview.rpi'),
            value: 'rpi',
            disabled: this.existAttr
          }
        ];
      }
    },
    params: {
      get() {
        return [
          {
            text: this.$t('overview.impressions'),
            value: 'impressions',
          },
          {
            text: this.$t('overview.taps'),
            value: 'taps',
          },
          {
            text: this.$t('overview.ttr'),
            value: 'ttr',
          },
          {
            text: this.$t('overview.spend'),
            value: 'localSpend',
          },
          {
            text: this.$t('overview.installs'),
            value: 'installs',
          },
          {
            text: this.$t('overview.avgCPA'),
            value: 'avgCPA',
          },
          {
            text: this.$t('overview.avgCPT'),
            value: 'avgCPT',
          },
          {
            text: this.$t('overview.conversionRate'),
            value: 'conversionRate',
          },
          {
            text: this.$t('overview.latOnInstalls'),
            value: 'latOnInstalls',
          },
          {
            text: this.$t('overview.latOffInstalls'),
            value: 'latOffInstalls',
          },
          {
            text: this.$t('overview.reDownloads'),
            value: 'redownloads',
          },
          {
            text: this.$t('overview.newDownloads'),
            value: 'newDownloads',
          },
        ];
      }
    },
    paramsDD11: {
      get() {
        return this.paramsDD11Temp
      },
      set(e) {
        this.paramsDD11Temp = e
      }
    },
    paramsDD12: {
      get() {
        return this.paramsDD12Temp
      },
      set(e) {
        this.paramsDD12Temp = e
      }
    },
    paramsDD13: {
      get() {
        return this.paramsDD13Temp
      },
      set(e) {
        this.paramsDD13Temp = e
      }
    },
    paramsDD14: {
      get() {
        return this.paramsDD14Temp
      },
      set(e) {
        this.paramsDD14Temp = e
      }
    },
    paramsDD15: {
      get() {
        return this.paramsDD15Temp
      },
      set(e) {
        this.paramsDD15Temp = e
      }
    },
    paramsDD16: {
      get() {
        return this.paramsDD16Temp
      },
      set(e) {
        this.paramsDD16Temp = e
      }
    },
    periods: {
      get() {
        return [
          {
            text: this.$t('overview.lastDay'),
            value: 1,
          },
          {
            text: this.$t('overview.last7Days'),
            value: 7,
          },
          {
            text: this.$t('overview.last30Days'),
            value: 30,
          },
        ];
      },
    },
    period: {
      get() {
        return {
          text: this.$t('overview.last7Days'),
          value: 7,
        };
      },
      set(e){
        this.period.text = e.text
        this.period.value = e.value
      }
    },
    searchApp: {
      get() {
        return {
          adamId: 0,
          appName: this.$t('overview.all'),
        };
      },
      set(e){
        this.searchApp.adamId = e.adamId
        this.searchApp.appName = e.appName
      }
    },
  },
  methods: {
    initializeDD(){
      this.dd11 = {
        selected: {
          text: this.$t('overview.impressions'),
          value: 'impressions',
        },
        value: 0,
        percent: 0,
        positive: true,
      }
      this.dd12 = {
        selected: {
          text: this.$t('overview.taps'),
          value: 'taps',
        },
        value: 0,
        percent: 0,
        positive: true,
      }
      this.dd13 = {
        selected: {
          text: this.$t('overview.installs'),
          value: 'installs',
        },
        value: 0,
        percent: 0,
        positive: true,
      }
      this.dd14 = {
        selected: {
          text: this.$t('overview.spend'),
          value: 'localSpend',
        },
        value: 0,
        percent: 0,
        positive: true,
      }
      this.dd15 = {
        selected: {
          text: this.$t('overview.avgCPA'),
          value: 'avgCPA',
        },
        value: 0,
        percent: 0,
        positive: true,
      }
      this.dd16 = {
        selected: {
          text: this.$t('overview.avgCPT'),
          value: 'avgCPT',
        },
        value: 0,
        percent: 0,
        positive: true,
      }
      this.dd21 = {
        selected: {
          text: this.$t('overview.impressions'),
          value: 'impressions',
        },
        value: 0,
        percent: 0,
        positive: true,
      }
      this.dd22 = {
        selected: {
          text: this.$t('overview.impressions'),
          value: 'installs',
        },
        value: 0,
        percent: 0,
        positive: true,
      }
      this.dd23 = {
        selected: {
          text: this.$t('overview.impressions'),
          value: 'installs',
        },
        value: 0,
        percent: 0,
        positive: true,
      }
      this.dd31 = {
        selected: {
          text: this.$t('overview.spend'),
          value: 'localSpend',
        },
        value: 0,
        percent: 0,
        positive: true,
      }
      this.dd32 = {
        selected: {
          text: this.$t('overview.spend'),
          value: 'localSpend',
        },
        value: 0,
        percent: 0,
        positive: true,
      }
    },
    changePeriod() {
      this.getOverview();
      this.getOverviewDevice();
      this.getOverviewAlone();
      this.getOverviewMap();
      this.getTopAdGroups();
      this.getTopKeywords();
      this.initializeDD()
    },
    changeApp(e) {
      this.checkAppAttr();
      this.getOverview();
      this.getOverviewDevice();
      this.getOverviewAlone();
      this.getOverviewMap();
      this.getTopAdGroups();
      this.getTopKeywords();
      this.initializeDD()
    },
    changedd11(e) {
      this.bindData('dd11', this.dd11.selected.value);
      this.repopulateDDNew();
    },
    changedd12() {
      this.bindData('dd12', this.dd12.selected.value);
      this.repopulateDDNew();
    },
    changedd13() {
      this.bindData('dd13', this.dd13.selected.value);
      this.repopulateDDNew();
    },
    changedd14() {
      this.bindData('dd14', this.dd14.selected.value);
      this.repopulateDDNew();
    },
    changedd15() {
      this.bindData('dd15', this.dd15.selected.value);
      this.repopulateDDNew();
    },
    changedd16() {
      this.bindData('dd16', this.dd16.selected.value);
      this.repopulateDDNew();
    },
    repopulateDD() {
      this.paramsDD11 = [];
      this.paramsDD11.push(this.dd11.selected);
      for (let index = 0; index < this.paramsFull.length; index++) {
        const element = this.paramsFull[index];
        if (
          element.value === this.dd12.selected.value ||
          element.value === this.dd13.selected.value ||
          element.value === this.dd14.selected.value
        ) {
          continue;
        }
        this.paramsDD11.push(element);
      }
      this.paramsDD12 = [];
      this.paramsDD12.push(this.dd12.selected);
      for (let index = 0; index < this.paramsFull.length; index++) {
        const element = this.paramsFull[index];
        if (
          element.value === this.dd11.selected.value ||
          element.value === this.dd13.selected.value ||
          element.value === this.dd14.selected.value
        ) {
          continue;
        }
        this.paramsDD12.push(element);
      }
      this.paramsDD13 = [];
      this.paramsDD13.push(this.dd13.selected);
      for (let index = 0; index < this.paramsFull.length; index++) {
        const element = this.paramsFull[index];
        if (
          element.value === this.dd11.selected.value ||
          element.value === this.dd12.selected.value ||
          element.value === this.dd14.selected.value
        ) {
          continue;
        }
        this.paramsDD13.push(element);
      }
      this.paramsDD14 = []
      this.paramsDD14.push(this.dd14.selected);
      for (let index = 0; index < this.paramsFull.length; index++) {
        const element = this.paramsFull[index];
        if (
          element.value === this.dd11.selected.value ||
          element.value === this.dd12.selected.value ||
          element.value === this.dd13.selected.value
        ) {
          continue;
        }
        this.paramsDD14.push(element);
      }
    },
    repopulateDDNew() {
      this.paramsDD11 = this.paramsFull.filter(e =>
        e.value !== this.dd14.selected.value &&
        e.value !== this.dd12.selected.value &&
        e.value !== this.dd15.selected.value &&
        e.value !== this.dd16.selected.value &&
        e.value !== this.dd13.selected.value
      )
      this.paramsDD12 = this.paramsFull.filter(e =>
        e.value !== this.dd11.selected.value &&
        e.value !== this.dd14.selected.value &&
        e.value !== this.dd15.selected.value &&
        e.value !== this.dd16.selected.value &&
        e.value !== this.dd13.selected.value
      )
      this.paramsDD13 = this.paramsFull.filter(e =>
        e.value !== this.dd11.selected.value &&
        e.value !== this.dd12.selected.value &&
        e.value !== this.dd15.selected.value &&
        e.value !== this.dd16.selected.value &&
        e.value !== this.dd14.selected.value
      )
      this.paramsDD14 = this.paramsFull.filter(e =>
        e.value !== this.dd11.selected.value &&
        e.value !== this.dd12.selected.value &&
        e.value !== this.dd15.selected.value &&
        e.value !== this.dd16.selected.value &&
        e.value !== this.dd13.selected.value
      )
      this.paramsDD15 = this.paramsFull.filter(e =>
        e.value !== this.dd11.selected.value &&
        e.value !== this.dd12.selected.value &&
        e.value !== this.dd14.selected.value &&
        e.value !== this.dd16.selected.value &&
        e.value !== this.dd13.selected.value
      )
      this.paramsDD16 = this.paramsFull.filter(e =>
        e.value !== this.dd11.selected.value &&
        e.value !== this.dd12.selected.value &&
        e.value !== this.dd15.selected.value &&
        e.value !== this.dd14.selected.value &&
        e.value !== this.dd13.selected.value
      )
    },
    changedd21() {
      const key = this.dd21.selected.value;
      // const currObjM = this.currOverviewM.grandTotal;
      // const prevObjM = this.prevOverviewM.grandTotal;
      // const currObjF = this.currOverviewF.grandTotal;
      // const prevObjF = this.prevOverviewF.grandTotal;

      const currObjM = this.currOverviewM;
      const prevObjM = this.prevOverviewM;
      const currObjF = this.currOverviewF;
      const prevObjF = this.prevOverviewF;

      let mp = 0;
      let mc = 0;
      let fp = 0;
      let fc = 0;

      if (key == 'localSpend' || key == 'avgCPT' || key == 'avgCPA') {
        mc = currObjM[key].amount;
        mp = prevObjM[key].amount;
        fc = currObjF[key].amount;
        fp = prevObjF[key].amount;
      } else {
        mc = currObjM[key];
        mp = prevObjM[key];
        fc = currObjF[key];
        fp = prevObjF[key];
      }

      const gData = {
        mp: mp,
        mc: mc,
        fp: fp,
        fc: fc,
        t: this.dd21.selected.value,
        ts: Date.now(),
      };
      this.genderData = gData;
    },
    changedd22() {
      const key = this.dd22.selected.value;
      //console.log(key);
      const currObjM = this.currOverviewPad;
      const prevObjM = this.prevOverviewPad;
      const currObjF = this.currOverviewPhone;
      const prevObjF = this.prevOverviewPhone;

      let mp = 0;
      let mc = 0;
      let fp = 0;
      let fc = 0;

      if (key == 'localSpend' || key == 'avgCPT' || key == 'avgCPA') {
        mc = currObjM[key].amount;
        mp = prevObjM[key].amount;
        fc = currObjF[key].amount;
        fp = prevObjF[key].amount;
      } else {
        mc = currObjM[key];
        mp = prevObjM[key];
        fc = currObjF[key];
        fp = prevObjF[key];
      }

      const dData = {
        mp: mp,
        mc: mc,
        fp: fp,
        fc: fc,
        t: this.dd22.selected.value,
        ts: Date.now(),
      };
      //console.log(dData);
      this.deviceData = dData;
    },
    changedd23() {
      const key = this.dd23.selected.value;
      this.locationData = this.mapData[key] ? this.mapData[key] : {};
    },
    changedd31() {
      const key = this.dd31.selected.value;
      this.getTopKeywords();
    },
    changedd32() {
      const key = this.dd32.selected.value;
      this.getTopAdGroups();
    },
    getOverview() {
      const dt = new Date();
      let month = '';
      const monthLength = dt.getMonth().toString().length;
      if (monthLength == 1) month = '0' + (dt.getMonth() + 1).toString();
      else month = (dt.getMonth() + 1).toString();
      const todayDate =
        dt.getFullYear().toString() + '-' + month + '-' + dt.getDate();

      let prevDate = '';

      if (this.period.value == 7) {
        prevDate = this.getOnlydate(7, todayDate);
      } else if (this.period.value == 30) {
        prevDate = this.getOnlydate(30, todayDate);
      } else if (this.period.value == 1) {
        prevDate = this.getOnlydate(1, todayDate);
      } else {
        prevDate = this.getOnlydate(0, todayDate);
      }

      //console.log(this.searchApp.adamId, this.period.text, todayDate, prevDate);

      this.$apollo
        .query({
          query: APP_OVERVIEW,
          variables: {
            period: parseInt(this.period.value),
            gender: 'A',
            location: 'ALL',
            device: 'ALL',
            currdate: todayDate,
            prevdate: prevDate,
            appId: this.searchApp.adamId,
            change: new Date().toISOString(),
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.currOverviewM = res.data.currentOverview
            ? res.data.currentOverview.grandTotal.male
            : {};
          this.prevOverviewM = res.data.previousOverview
            ? res.data.previousOverview.grandTotal.male
            : {};
          this.currOverviewF = res.data.currentOverview
            ? res.data.currentOverview.grandTotal.female
            : {};
          this.prevOverviewF = res.data.previousOverview
            ? res.data.previousOverview.grandTotal.female
            : {};
          this.changedd21();
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    getOverviewDevice() {
      const dt = new Date();
      let month = '';
      const monthLength = dt.getMonth().toString().length;
      if (monthLength == 1) month = '0' + (dt.getMonth() + 1).toString();
      else month = (dt.getMonth() + 1).toString();
      const todayDate =
        dt.getFullYear().toString() + '-' + month + '-' + dt.getDate();

      let prevDate = '';

      if (this.period.value == 7) {
        prevDate = this.getOnlydate(7, todayDate);
      } else if (this.period.value == 30) {
        prevDate = this.getOnlydate(30, todayDate);
      } else if (this.period.value == 1) {
        prevDate = this.getOnlydate(1, todayDate);
      } else {
        prevDate = this.getOnlydate(0, todayDate);
      }

      //console.log(this.searchApp.adamId, this.period.text, todayDate, prevDate);

      this.$apollo
        .query({
          query: APP_OVERVIEW_DEVICE,
          variables: {
            period: parseInt(this.period.value),
            gender: 'A',
            location: 'ALL',
            device: 'ALL',
            currdate: todayDate,
            prevdate: prevDate,
            appId: this.searchApp.adamId,
            change: new Date().toISOString(),
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.currOverviewPad = res.data.currentOverview
            ? res.data.currentOverview.grandTotal.iphone
            : {};
          this.prevOverviewPad = res.data.previousOverview
            ? res.data.previousOverview.grandTotal.iphone
            : {};
          this.currOverviewPhone = res.data.currentOverview
            ? res.data.currentOverview.grandTotal.ipad
            : {};
          this.prevOverviewPhone = res.data.previousOverview
            ? res.data.previousOverview.grandTotal.ipad
            : {};
          this.changedd22();
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    getOverviewAlone() {
      this.fullscreenLoaderAlone = this.$loading.show();
      const dt = new Date();
      let month = '';
      const monthLength = dt.getMonth().toString().length;
      if (monthLength == 1) month = '0' + (dt.getMonth() + 1).toString();
      else month = (dt.getMonth() + 1).toString();
      const todayDate =
        dt.getFullYear().toString() + '-' + month + '-' + dt.getDate();

      let prevDate = '';

      if (this.period.value == 7) {
        prevDate = this.getOnlydate(7, todayDate);
      } else if (this.period.value == 30) {
        prevDate = this.getOnlydate(30, todayDate);
      } else if (this.period.value == 1) {
        prevDate = this.getOnlydate(1, todayDate);
      } else {
        prevDate = this.getOnlydate(0, todayDate);
      }

      //console.log(this.searchApp.adamId, this.period.text, todayDate, prevDate);

      this.$apollo
        .query({
          query: APP_OVERVIEW_ALONE,
          variables: {
            period: parseInt(this.period.value),
            gender: 'A',
            location: 'ALL',
            device: 'ALL',
            currdate: todayDate,
            prevdate: prevDate,
            appId: this.searchApp.adamId,
            change: new Date().toISOString(),
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.currOverview = res.data.currentOverviewAlone;
          this.prevOverview = res.data.previousOverviewAlone;
          this.bindData('dd11', 'impressions');
          this.bindData('dd12', 'taps');
          this.bindData('dd13', 'installs');
          this.bindData('dd14', 'localSpend');
          this.bindData('dd15', 'avgCPA');
          this.bindData('dd16', 'avgCPT');
          this.fullscreenLoaderAlone.hide();
        })
        .catch((error) => {
          this.fullscreenLoaderAlone.hide();
        });
    },
    getOverviewMap() {
      const dt = new Date();
      let month = '';
      const monthLength = dt.getMonth().toString().length;
      if (monthLength == 1) month = '0' + (dt.getMonth() + 1).toString();
      else month = (dt.getMonth() + 1).toString();
      const todayDate =
        dt.getFullYear().toString() + '-' + month + '-' + dt.getDate();

      let prevDate = '';

      if (this.period.value == 7) {
        prevDate = this.getOnlydate(7, todayDate);
      } else if (this.period.value == 30) {
        prevDate = this.getOnlydate(30, todayDate);
      } else if (this.period.value == 1) {
        prevDate = this.getOnlydate(1, todayDate);
      } else {
        prevDate = this.getOnlydate(0, todayDate);
      }

      //console.log(this.searchApp.adamId, this.period.text, todayDate, prevDate);

      this.$apollo
        .query({
          query: APP_OVERVIEW_MAP,
          variables: {
            period: parseInt(this.period.value),
            gender: 'A',
            location: 'ALL',
            device: 'ALL',
            currdate: todayDate,
            prevdate: prevDate,
            appId: this.searchApp.adamId,
            change: new Date().toISOString(),
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.mapData = res.data.getMapOverview
            ? res.data.getMapOverview.grandTotal
            : {};
          this.locationData = this.mapData['installs']
            ? this.mapData['installs']
            : {};
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    getOwnApps() {
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: OWN_APPS,
          variables: {
            from: 'overview',
          },
        })
        .then((res) => {
          console.log(res.data);
          this.searchedApps = res.data.getAllOwnApps;
          this.searchedApps.push({
            adamId: 0,
            appName: 'All',
          });
          this.searchApp = {
            adamId: 0,
            appName: 'All',
          };
          this.fullscreenLoader.hide();
        })
        .catch((error) => {
          this.fullscreenLoader.hide();
        });
    },
    checkAppAttr() {
      this.$apollo
        .query({
          query: CHECK_APP_ATTR,
          variables: {
            appId: this.searchApp.adamId,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.existAttr =  res.data.checkAppAttr.code == 0 ? false : true
          this.repopulateDDNew()
        })
        .catch((error) => {
          //
        });
    },
    getOnlydate(period, date) {
      const dt = new Date(date);
      dt.setDate(dt.getDate() - period);

      let month = '';
      let day = '';

      const monthLength = dt.getMonth().toString().length;
      const dayLength = dt.getDate().toString().length;

      if (monthLength == 1) month = '0' + (dt.getMonth() + 1).toString();
      else month = (dt.getMonth() + 1).toString();

      if (dayLength == 1) day = '0' + dt.getDate().toString();
      else day = dt.getDate().toString();

      const todayDate = dt.getFullYear().toString() + '-' + month + '-' + day;
      return todayDate;
    },
    bindData(from, key) {
      const comp = this[from];
      const currObj = this.currOverview.grandTotal;
      const prevObj = this.prevOverview.grandTotal;
      if (key == 'localSpend' || key == 'avgCPT' || key == 'avgCPA') {
        //console.log(key)
        comp.displayCurr = true
        comp.curr = currObj[key].currency;
        if (currObj.hasOwnProperty(key)) {
          comp.value =
            this.numberWithCommas(currObj[key].amount)
            //+ ' ' + currObj[key].currency;
          if (currObj[key].amount > prevObj[key].amount) {
            comp.positive = true;
            comp.percent =
              100 -
              Math.round((prevObj[key].amount / currObj[key].amount) * 100);
          }
          if (currObj[key].amount < prevObj[key].amount) {
            comp.positive = false;
            comp.percent =
              100 -
              Math.round((currObj[key].amount / prevObj[key].amount) * 100);
          }
          if ((currObj[key].amount == prevObj[key].amount)) {
            comp.positive = true;
            comp.percent = 0;
          }
        }
        return;
      }
      if (currObj.hasOwnProperty(key)) {
        //console.log(this.numberWithCommas(currObj[key]));
        comp.displayCurr = false
        comp.curr = ''
        comp.value = this.numberWithCommas(currObj[key]);
        if (currObj[key] > prevObj[key]) {
          comp.positive = true;
          comp.percent = 100 - Math.round((prevObj[key] / currObj[key]) * 100);
        }
        if (currObj[key] < prevObj[key]) {
          comp.positive = false;
          comp.percent = 100 - Math.round((currObj[key] / prevObj[key]) * 100);
        }
        if ((currObj[key] == prevObj[key])) {
          comp.positive = true;
          comp.percent = 0;
        }
      }
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    },
    getTopAdGroups() {
      //console.log(this.searchApp.adamId,this.dd32.selected.value,this.period.value);
      this.$apollo
        .query({
          query: TOP_ADS,
          variables: {
            appId: this.searchApp.adamId,
            param: this.dd32.selected.value,
            period: this.period.value,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.topAdgroups = res.data.getTopAds;
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    getTopKeywords() {
      //console.log(this.searchApp.adamId,this.dd31.selected.value,this.period.value);
      this.$apollo
        .query({
          query: TOP_KEYS,
          variables: {
            appId: this.searchApp.adamId,
            param: this.dd31.selected.value,
            period: this.period.value,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.topKeywords = res.data.getTopKeys;
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    goToDashBoard(tab) {
      //console.log('goToDashBoard', tab);
      this.$router.push({ path: '/ads-manager', query: { tab: tab } });
    },
    updateCheckList(){
      if(!LSS.token || !decodeToken(LSS.token).checkList){
        this.existCL = false
        return
      }else{
        this.existCL = true
      }
      //this.check1 = 'mdi-check-circle'
      if(LSS.token && decodeToken(LSS.token).checkList){
        const checkList = decodeToken(LSS.token).checkList
        //console.log(checkList)
        if((checkList.createCampaign && checkList.adsManager) && !checkList.overviewPage){
          updateCLF('overviewPage', this)
        }
        if(checkList.saIntegration && checkList.slackIntegration){
          this.check0 = 'mdi-check-circle'
          this.color0 = 'primary'
        }
        if(checkList.saIntegration){
          this.check1 = 'mdi-check-circle'
          this.color1 = 'primary'
        }
        if(checkList.kaiComp){
          this.check2 = 'mdi-check-circle'
          this.color2 = 'primary'
        }
        if(checkList.kaiRecom){
          this.check7 = 'mdi-check-circle'
          this.color7 = 'primary'
        }
        if(checkList.kaiApp){
          this.check8 = 'mdi-check-circle'
          this.color8 = 'primary'
        }
        if(checkList.kaiKeyword){
          this.check9 = 'mdi-check-circle'
          this.color9 = 'primary'
        }
        if(checkList.kaiTop){
          this.check10 = 'mdi-check-circle'
          this.color10 = 'primary'
        }
        if(checkList.kaiComp && checkList.kaiRecom && checkList.kaiApp && checkList.kaiKeyword && checkList.kaiTop){
          this.check11 = 'mdi-check-circle'
          this.color11 = 'primary'
        }
        if(checkList.createCampaign){
          this.check16 = 'mdi-check-circle'
          this.color16 = 'primary'
        }
        if(checkList.automationRules && checkList.createRule){
          this.check4 = 'mdi-check-circle'
          this.color4 = 'primary'
        }
        if(checkList.slackIntegration){
          this.check6 = 'mdi-check-circle'
          this.color6 = 'primary'
        }
        if(checkList.createCampaign){
          this.check12 = 'mdi-check-circle'
          this.color12 = 'primary'
        }
        if(checkList.createCampaign){
          this.check13 = 'mdi-check-circle'
          this.color13 = 'primary'
        }
        if(checkList.createCampaign){
          this.check14 = 'mdi-check-circle'
          this.color14 = 'primary'
        }
        if(checkList.createCampaign){
          this.check15 = 'mdi-check-circle'
          this.color15 = 'primary'
        }
        if(checkList.createCampaign && checkList.adsManager){
          this.check3 = 'mdi-check-circle'
          this.color3 = 'primary'
        }
        if(checkList.createCampaign && checkList.adsManager){
          this.check5 = 'mdi-check-circle'
          this.color5 = 'primary'
        }
      }
    }
  },
  watch: {},
  mounted() {
    this.loadingApps = true;
    this.getOwnApps();
    this.loadingApps = false;
    this.getOverview();
    this.getOverviewDevice();
    this.getOverviewAlone();
    this.getOverviewMap();
    this.getTopAdGroups();
    this.getTopKeywords();
    this.updateCheckList()
    this.$root.$on('userNameFetched', (userDet) => {
      //console.log('In ovw')
      this.updateCheckList()
    });
    this.$root.$on('locale-changed', (l) => {
      this.repopulateDDNew()
    });
    this.paramsDD11 = this.paramsFull.filter(e =>
        e.value !== 'installs' &&
        e.value !== 'taps' &&
        e.value !== 'avgCPA' &&
        e.value !== 'avgCPT' &&
        e.value !== 'localSpend'
      )
    this.paramsDD13 = this.paramsFull.filter(e =>
        e.value !== 'impressions' &&
        e.value !== 'taps' &&
        e.value !== 'avgCPA' &&
        e.value !== 'avgCPT' &&
        e.value !== 'localSpend'
      )
    this.paramsDD12 = this.paramsFull.filter(e =>
        e.value !== 'impressions' &&
        e.value !== 'installs' &&
        e.value !== 'avgCPA' &&
        e.value !== 'avgCPT' &&
        e.value !== 'localSpend'
      )
    this.paramsDD14 = this.paramsFull.filter(e =>
        e.value !== 'installs' &&
        e.value !== 'taps' &&
        e.value !== 'avgCPA' &&
        e.value !== 'avgCPT' &&
        e.value !== 'impressions'
      )
    this.paramsDD15 = this.paramsFull.filter(e =>
        e.value !== 'installs' &&
        e.value !== 'taps' &&
        e.value !== 'avgCPT' &&
        e.value !== 'localSpend' &&
        e.value !== 'impressions'
      )
    this.paramsDD16 = this.paramsFull.filter(e =>
        e.value !== 'installs' &&
        e.value !== 'taps' &&
        e.value !== 'localSpend' &&
        e.value !== 'avgCPA' &&
        e.value !== 'impressions'
      )
  },
});
</script>

<style scoped>
.displayFlex {
  display: flex !important;
}
.v-list-item a {
    color: rgba(64, 64, 66, 0.6);
    line-height: 1.375rem;
}
.colFullOverview .col-right-main .titleText a{
  color: rgba(0, 0, 0, 0.6);
}
.colFullOverview .col-right-main .v-list-item__content i.v-icon {
  padding-top: 5px;
}
</style>
